var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{attrs:{"height":"100%","width":"100%"}},[_c('v-navigation-drawer',{attrs:{"fixed":"","left":"","width":"450px","app":""},model:{value:(_vm.dialog_plates),callback:function ($$v) {_vm.dialog_plates=$$v},expression:"dialog_plates"}},[_c('v-toolbar',{staticClass:"fixed-bar",attrs:{"color":"#CFD8DC"}},[_c('v-toolbar-title',[_vm._v("Platos")]),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){_vm.dialog_plates = !_vm.dialog_plates}}},[_vm._v("mdi-arrow-left")])],1),_c('v-data-table',{staticClass:"table-cursor elevation-1",attrs:{"headers":_vm.header_items.true,"items":_vm.items,"items-per-page":-1,"item-key":"code","sort-by":"name","search":_vm.search_plate,"mobile-breakpoint":"0","loading-text":"Cargando ..."},on:{"click:row":_vm.select_plate},scopedSlots:_vm._u([{key:"item.picture",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',[(item.picture)?_c('v-img',{attrs:{"src":_vm.getImagePhoto(item.account, item.store, item.picture)}}):_vm._e()],1)]}},{key:"item.product_name",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.product_name))]),_c('small',{domProps:{"innerHTML":_vm._s(item.description)}})]}}],null,true)})],1),_c('v-container',{staticClass:"pt-12"},[_c('v-app-bar',{staticClass:"fixed-bar-b",attrs:{"flat":"","color":"transparent","width":"100%"}},[_c('v-icon',{attrs:{"large":""},on:{"click":function($event){_vm.dialog_plates = !_vm.dialog_plates}}},[_vm._v("mdi-room-service-outline ")]),_c('v-spacer'),(_vm.plate_selected.code)?_c('v-icon',{attrs:{"large":""},on:{"click":function($event){_vm.dialog_smart = !_vm.dialog_smart}}},[_vm._v("mdi-plus ")]):_vm._e()],1),_c('v-card',{staticClass:"mt-6"},[_c('v-card-title',[_c('v-list',[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-avatar',{attrs:{"size":"80"}},[(_vm.plate_selected.picture)?_c('v-img',{attrs:{"src":_vm.getImagePhoto(
                    _vm.plate_selected.account,
                    _vm.plate_selected.store,
                    _vm.plate_selected.picture
                  )}}):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.plate_selected.product_name)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(_vm.plate_selected.description)}})],1)],1)],1)],1),_c('v-card-text',[_c('v-snackbar',{attrs:{"dark":"","color":"red","timeout":"2000"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_c('v-data-table',{attrs:{"headers":_vm.header_cost,"items":_vm.items_cost,"mobile-breakpoint":"0","items-per-page":-1,"loading":_vm.loading_status},scopedSlots:_vm._u([{key:"item.qty",fn:function(props){return [_c('v-edit-dialog',{attrs:{"return-value":props.item.qty},on:{"update:returnValue":function($event){return _vm.$set(props.item, "qty", $event)},"update:return-value":function($event){return _vm.$set(props.item, "qty", $event)},"save":_vm.save,"cancel":_vm.cancel,"open":_vm.open,"close":_vm.close},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"suffix":props.item.uom,"label":"Edit","single-line":"","clearable":""},model:{value:(props.item.qty),callback:function ($$v) {_vm.$set(props.item, "qty", $$v)},expression:"props.item.qty"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.qty)+" ")])]}},{key:"item.price",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.qty * item.price)+" ")]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1),_c('v-card-actions',[_c('v-row',[_c('v-col',{attrs:{"cols":"8","md":"8"}},[_vm._v("Total Cost of Dish")]),_c('v-col',{attrs:{"cols":"4","md":"4","align":"end"}},[_vm._v(_vm._s(this.totals.cost.toLocaleString(2)))])],1)],1)],1)],1),_c('v-navigation-drawer',{attrs:{"fixed":"","right":"","width":_vm.smart_width,"app":""},model:{value:(_vm.dialog_smart),callback:function ($$v) {_vm.dialog_smart=$$v},expression:"dialog_smart"}},[_c('v-toolbar',{staticClass:"fixed-bar",attrs:{"color":"#CFD8DC"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","fab":""},on:{"click":function($event){_vm.s_expand = !_vm.s_expand}}},[_c('v-icon',[_vm._v("mdi-arrow-split-vertical")])],1),_c('v-toolbar-title',[_vm._v("Smart List")]),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){_vm.dialog_smart = !_vm.dialog_smart}}},[_vm._v("mdi-arrow-right")])],1),_c('v-toolbar',{staticClass:"fixed-bar-b",attrs:{"color":"#CFD8DC"}},[_c('v-select',{attrs:{"rounded":"","items":_vm.smart_categories,"label":"Categoría","outlined":"","dense":"","hide-details":"auto"},on:{"change":_vm.smart_category}}),_c('v-spacer'),_c('v-btn',{attrs:{"x-small":"","color":"warning","fab":""},on:{"click":function($event){return _vm.newRaw()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-data-table',{staticClass:"table-cursor elevation-1",attrs:{"headers":_vm.header_raw[!_vm.s_expand],"items":_vm.smart_lst_selected,"items-per-page":15,"item-key":"code","sort-by":"name","search":_vm.search_smart,"mobile-breakpoint":"0","loading-text":"Cargando ..."},on:{"click:row":_vm.add_to_costs},scopedSlots:_vm._u([(_vm.item.picture !== '')?{key:"item.picture",fn:function(ref){
                  var item = ref.item;
return [(item.picture)?_c('v-avatar',[_c('v-img',{attrs:{"src":_vm.getImagePhoto(item.account, item.store, item.picture)}})],1):_vm._e()]}}:null,{key:"item.name",fn:function(ref){
                  var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.product_name))]),(_vm.$vuetify.breakpoint.xsOnly)?_c('span',[_c('small',[_vm._v(" ("+_vm._s(item.category)+")")]),_c('br'),_c('small',[_vm._v(" "+_vm._s(item.size)+" "+_vm._s(item.uom)+" ")])]):_vm._e()]}},{key:"item.size",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.size + " " + item.uom)+" "),_c('br')]}},{key:"item.price",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.price).toLocaleString(2))+" ")]}}],null,true)}),_c('v-bottom-navigation',{attrs:{"app":""}},[_c('v-toolbar',{attrs:{"color":"#CFD8DC"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"12","cols":"12"}},[_c('v-text-field',{attrs:{"hide-details":"","append-icon":"mdi-magnify","outlined":"","rounded":"","dense":"","placeholder":"Buscar producto Smart ..."},on:{"keypress":function($event){return _vm.update_selected()}},model:{value:(_vm.search_smart),callback:function ($$v) {_vm.search_smart=$$v},expression:"search_smart"}})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading_status),callback:function ($$v) {_vm.loading_status=$$v},expression:"loading_status"}}),_c('RawForm',{attrs:{"table":"raw_material","item":_vm.raw_item,"dialog":_vm.raw_dialog,"newItem":_vm.newRawItem,"store":_vm.store.code,"categories":_vm.smart_categories},on:{"close":function($event){_vm.raw_dialog = false},"refresh":function($event){return _vm.refresh_raw()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }